<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('service.forms.submissions')" class="service form_submissions">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-1 text-left">
              {{$t('forms.Form_submissions')}} - {{form.form_name}}
            </CCol>
            <CCol v-if="checkPermission('service.forms.exportsubmissions')" cols="6" xl="6" class="pt-0 pb-1 text-right">
              <CButton color="primary" @click="exportFormSubmissions('csv')">
                <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_csv') }}</span>
              </CButton>
              <CButton color="primary" @click="exportFormSubmissions('json');" class="m-0">
                <i class="fas fa-file-arrow-down mr-1"/><span>{{ $t('common.Export_as_json') }}</span>              
              </CButton>              
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div class="submission_filters">
            <CRow class="m-0">
              <CCol cols="4" lg="4" class="pt-0 pb-0 search">
                <label><b>{{$t('forms.Search_within_form_submissions')}}</b></label>
                <CInput type="text" class="mb-0 searchbar" v-model="submissionFilters.search_term" v-debounce:1s="filterFormSubmissions"></CInput>
              </CCol>
              <CCol cols="8" lg="8" class="pt-0 pb-0">
                <label><b>{{$t('forms.Form_fields_to_include')}}</b></label>
                <multiselect class="data_table open_absolute"
                              v-model="selectedFormFields" 
                              :options="formFields" 
                              :multiple="true"
                              :close-on-select="false"             
                              :placeholder="$t('forms.Select_form_fields')"
                              :selectLabel="$t('forms.Add_form_field')" 
                              :selectedLabel="$t('Added')"
                              :deselectLabel="$t('forms.Remove_form_field')"
                              track-by="form_config_field_id" 
                              label="field_name"                                    
                              @input="setSelectedFormFieldNames()">
                  <span slot="noResult">{{ $t('common.no_form_fields_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>
              </CCol>       
            </CRow>
          </div>
          <hr class="mt-2_5" v-bind:class="{'mb-2_5' : submissions.length > 0, 'mb-0' : submissions.length == 0}">
          <div class="data_table_container">
            <CRow v-if="submissionsLoading === true">
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0': submissions.length > 0}">
                <div v-if="submissions.length > 0">
                  <b-table ref="submissionsTable"
                          class="data_table includes_dropdown"
                          :data="submissions"
                          :striped="true"
                          :hoverable="true"
                          :mobile-cards="true"
                          :current-page.sync="currentPage"
                          backend-pagination
                          :paginated="isPaginated"
                          :total="totalItems"
                          :per-page="perPage"
                          :pagination-simple="isPaginationSimple"
                          :pagination-position="paginationPosition"
                          @page-change="onPageChange"
                          backend-sorting
                          :default-sort="[sortField, sortOrder]"
                          :default-sort-direction="defaultSortDirection"
                          :sort-icon="sortIcon"
                          :sort-icon-size="sortIconSize"                   
                          @sort="onTableSort">
                    
                    <template slot-scope="props">
                      <b-table-column field="image" label="" :searchable="false" :sortable="false" width="2.5%">
                        <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.submitter" :userProfileImage="props.row.submitter_profile_image" :userIdExternal="props.row.submitter_user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                      </b-table-column>                  
                      <b-table-column field="submitter" :label="$t('forms.Submitter')" :searchable="searchEnabled" :sortable="true">
                        <span>{{props.row.submitter}}</span>
                        <span class="d-block meta mt-1">
                          <span>{{props.row.team_name}} ({{props.row.department_name}})</span>
                          <span v-if="props.row.work_location"> | {{props.row.work_location}}</span>
                        </span>                        
                      </b-table-column>                     
                      <b-table-column v-for="(field, index) in formFields" 
                                      :key="index"
                                      :field="field.field_name"
                                      :label="field.field_name" 
                                      :searchable="searchEnabled" 
                                      :sortable="true"
                                      :visible="selectedFormFieldNames.includes(field.field_name)">                        

                        <truncate action-class="read_more_btn" clamp="..." :length="75" type="text" :text="props.row.submission_values[field.field_name]"></truncate>                                                
                      </b-table-column>
                      <b-table-column field="submitted" :label="$t('forms.Submitted_at')" :searchable="searchEnabled" :sortable="true">
                        <span>{{props.row.submitted | moment("DD-MM-YYYY HH:ss")}}</span>
                      </b-table-column>
                      <b-table-column field="form_routes" :label="$t('forms.Form_routes')" width="20%">
                        <b-tag v-for="route in props.row.form_routes" v-bind:key="route.form_route_id_external" class="mt-1 info">{{route.route_name}}</b-tag>
                        <span v-if="props.row.form_routes.length === 0">-</span>
                      </b-table-column>
                      <b-table-column field="actions" label="" width="10%">
                        <div class="d-flex justify-content-lg-end">
                          <CDropdown color="primary" toggler-text="" :caret="false" placement="bottom-end" class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="openSidebarRight('form_submission_details', { form_submission_id_external: props.row.form_submission_id_external });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fa-solid fa-chevron-right"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('forms.Show_submission')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="exportFormSubmissions('csv', props.row.form_submission_id_external)">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fa-solid fa-file-arrow-down"></i>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('common.Export_as_csv')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="exportFormSubmissions('json', props.row.form_submission_id_external)">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fa-solid fa-file-arrow-down"></i>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('common.Export_as_json')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>                            
                          </CDropdown>                    
                        </div>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="text-center">
                        <span>{{$t('forms.No_form_submissions_available')}}</span>                    
                      </div>                
                    </template>                
                  </b-table>
                </div>
                <div v-else class="text-center">
                  <span v-if="submissionFilters.search_term">{{$t('forms.No_forms_based_on_filters')}}</span>
                  <span v-else>{{$t('forms.No_form_submissions_available')}}</span>
                </div>                
              </CCol>
            </CRow>            
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import truncate from 'vue-truncate-collapsed';
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'FormSubmissions',
  components: {
    truncate,
    Multiselect,
    loadingSpinner,
    noPermission,
    userProfilePopover
  },
  watch: { 
    '$route.params.id': {
      handler() {
        // Get the formIdExternal from the route params
        this.formIdExternal = this.$route.params.id;     
      },
      deep: true,
      immediate: true
    }
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      formIdExternal: null,
      formConfigIdExternal: null,
      form: {},
      submissions: [],
      submissionsLoading: true,
      submissionsLoaded: false,      
      submissionFilters: {
        search_term: '',
        form_fields: []
      },      
      selectedFormFields: [],
      selectedFormFieldNames: [],
      formFields: [],
      sortField: 'submitted',
      sortOrder: 'desc',
      defaultSortDirection: 'desc',
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: false,
      totalItems: 0,
      submissionsStartIndex: 0
    }
  },
  methods: {
    getFormDetails() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/form/' + this.formIdExternal)
      .then(res => {
        this.form = res.data.data;
        // Set the formConfigIdExternal value
        this.formConfigIdExternal = res.data.data.form_config_id_external;
        // Get the form fields
        this.getFormFields();
        // Wait 250ms and get the form submissions
        setTimeout(function() {
          this.getFormSubmissions();
        }.bind(this), 500);
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getFormFields() {
      let params = {};
      params.form_config_id_external = this.formConfigIdExternal;

      axios.post(process.env.VUE_APP_API_URL + '/v1/service/form/' + this.formIdExternal + '/fields', params)
      .then(res => {
        // Set the formFields
        this.formFields = res.data.data;
        // Set the selectedFormFields
        this.selectedFormFields = res.data.data;
        // Trigger setSelectedFormFieldNames method
        this.setSelectedFormFieldNames();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getFormSubmissions() {
      // Start the loader
      if(this.submissionsLoaded === false) this.submissionsLoading = true;
      // Set the params
      let params = {};
      params.submissionFilters = this.submissionFilters;
      params.submissionFilters.form_config_id_external = this.form.form_config_id_external; 
      params.submissionFilters.sort_field = this.sortField;
      params.submissionFilters.sort_order = this.sortOrder;
      params.submissionFilters.form_fields = [];

      for(let f = 0; f < this.selectedFormFields.length; f++) {
        params.submissionFilters.form_fields.push(this.selectedFormFields[f].form_config_field_id);
      }

      // Get the submissions
      axios.post(process.env.VUE_APP_API_URL + '/v1/service/form/' + this.formIdExternal + '/submissions/' + this.submissionsStartIndex, params)
      .then(res => {
        // Clear the submissions array
        this.submissions = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_submissions;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the submissions to the submissions array
        this.submissions = res.data.data.submissions;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.submissionsLoading = false;
        // Update the submissionsLoaded value
        this.submissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    exportFormSubmissions(outputFormat, formSubmissionIdExternal = null) {
      // Reset the submissionsExportPath and submissionsExportSuccessMessage values
      let submissionsExportPath = null;
      let submissionsExportSuccessMessage = null;
      // Set the params
      let params = {};      
      params.form_config_id_external = this.form.form_config_id_external; 
      params.form_fields = [];
      // Add the field ID's to the form_fields array
      for(let f = 0; f < this.selectedFormFields.length; f++) {
        params.form_fields.push(this.selectedFormFields[f].form_config_field_id);
      }      
      // Set the submissionsExportPath and submissionsExportSuccessMessage values based on the formSubmissionIdExternal value
      if(formSubmissionIdExternal) {
        submissionsExportPath = 'service/form/' + this.formIdExternal + '/submission/' + formSubmissionIdExternal + '/export/' + outputFormat;
        submissionsExportSuccessMessage = this.$t('forms.Submissions_exported_succesfully');
      } else {
        submissionsExportPath = 'service/form/' + this.formIdExternal + '/submissions/export/' + outputFormat;
        submissionsExportSuccessMessage = this.$t('forms.Submission_exported_succesfully');
      }

      // Export the submission(s)
      axios.post(process.env.VUE_APP_API_URL + '/v1/' + submissionsExportPath, params)    
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: submissionsExportSuccessMessage, type: 'is-success', duration: 2000 });
        // Set the filename
        let exportFileName = this.form.form_name.replace(/\s+/g, '-').toLowerCase() + '-form-submissions';        
        
        if(!window.navigator.msSaveOrOpenBlob) {
          // Blob navigator
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;
            url = window.URL.createObjectURL(new Blob([outputData]));  
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
            url = window.URL.createObjectURL(new Blob([outputData]));
          }
          // Create a new 'a' element
          const link = document.createElement('a');
          // Set the generated URL as href of the new element
          link.href = url;
          // Set the download attribute based on the output format
          link.setAttribute('download', exportFileName + '.' + outputFormat);
          // Append the link to the body
          document.body.appendChild(link);
          // Click the link
          link.click();
        } else {
          // Blob for Explorer 11
          let url = null;
          let outputData = null;
          // Set the output data and URL based on the output format
          if(outputFormat === 'csv') {
            outputData = res.data;            
          } else if(outputFormat === 'json') {
            outputData = JSON.stringify(res.data.data);
          }

          url = window.navigator.msSaveOrOpenBlob(new Blob([outputData]), exportFileName + '.' + outputFormat);
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },        
    onPageChange(page) {
      // Set the submissionsStartIndex value
      (page > 1) ? this.submissionsStartIndex = (page - 1)*10 : this.submissionsStartIndex = 0;
      // Get the rewards
      this.getFormSubmissions();
    },
    onTableSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // Reset the pagination;
      this.resetPagination();
      // Get the submissions
      this.getFormSubmissions()
    },
    setSelectedFormFieldNames() {
      // Reset the selectedFormFieldNames array
      this.selectedFormFieldNames = [];
      // Add the wanted fields to the selectedFormFieldNames array
      for(let f = 0; f < this.selectedFormFields.length; f++) {
        this.selectedFormFieldNames.push(this.selectedFormFields[f].field_name);
      }
    },    
    filterFormSubmissions() {
      // Reset the pagination;
      this.resetPagination();
      // Get the submissions
      this.getFormSubmissions();
    },        
    resetPagination() {
      this.currentPage = 1;
      this.submissionsStartIndex = 0;
    },          
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }          
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
        
    this.getPlatformPermissions();
    this.getFormDetails();
  }
}
</script>